import { BadRequest } from '@tsed/exceptions';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LoginForm from '@/components/profile/LoginForm';
import { homeConfig, commonConfig } from '../lib/i18n/configs';

type Props = {
  /** Add Route props here */
};

export default function Login(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return (
    <>
      <LoginForm name="loginFormAieia" />
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const i18nNamespaces = [
    ...homeConfig.i18nNamespaces,
    ...commonConfig.i18nNamespaces,
  ];

  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
